import { ChatIcon, ClockIcon, DeviceMobileIcon, RefreshIcon } from '@heroicons/react/outline'
import { CursorClickIcon } from '@heroicons/react/outline'
import { EyeOffIcon } from '@heroicons/react/outline'
import { PaperAirplaneIcon } from '@heroicons/react/outline'

const projects = [
  {
    'client': 'Youthworks',
    'title': 'Transform a paper-driven camp registation process to a digital process.',
    'img_folder': process.env.PUBLIC_URL + '/projects/yw/',
    'challenge': <div><p className="mb-4">YouthWorks puts on summer camps for tens of thousands of students every summer. As with any youth summer camp, there's all kinds of forms you're required to fill out to attend - medical releases, covenants, permissions slips, etc.</p><p>Traditionally, this process has been extremely manual and paper driven, involving copies upon copies and staff members toting around literal suitcases of paper.</p></div>,
    'solution': 'YouthWorks contracted Good Gravy to "Go Paperless" and transform their process to be entirely digital',
    'benefits': [
      {
        icon: ClockIcon,
        title: 'Countless Hours Saved',
        subtitle: 'Admin overhead of entering and processing paper forms was saved.'
      },
      {
        icon: CursorClickIcon,
        title: 'Easier Onboarding',
        subtitle: 'The camper & staff experience with YouthWorks was simplified with less touch points.'
      },
      {
        icon: EyeOffIcon,
        title: 'Better Privacy',
        subtitle: 'Access to forms was distributed more effectively on a need-to-know basis, instead of in a paper binder.'
      },
      {
        icon: PaperAirplaneIcon,
        title: 'Paperless',
        subtitle: 'So. Much. Paper. Saved.'
      }
    ],
    'screens': [
      {
        image: 'landing.png',
        title: 'Trip Landing',
        subtitle: 'One stop shop for those interested in attending a trip.'
      },
      {
        image: 'forms-1.png',
        title: 'Forms',
        subtitle: 'Extensive, comprehensive secure data collection.'
      },
      {
        image: 'forms-2.png',
        title: 'Forms',
        subtitle: 'Ensuring quality data consistency and storage.'
      },
      {
        image: 'perms.png',
        title: 'Better Permissions',
        subtitle: 'Finely tuned user permissions so all data is access on need-to-know basis.'
      },
      {
        image: 'roster.png',
        title: 'Roster',
        subtitle: 'Roster visibility for admin/staff.'
      },
      {
        image: 'super-admin.png',
        title: 'Super Admin',
        subtitle: 'Extensive super admin access to change & access most anything across the system.'
      }
    ],
    techs : [
      {
        title: 'React',
        description: 'Front end of the application is built using React.'
      },
      {
        title: 'Next.JS',
        description: 'Front end utilizes Next.js.'
      },
      {
        title: 'Laravel',
        description: 'Back end API is built using Laravel.'
      },
      {
        title: 'Dynamics 365',
        description: 'Deep integration with Dynamics 365 CRM.'
      }
    ],
    process: <div>
      <p className="mt-8 text-xl text-gray-500 leading-8">
        We worked with YouthWorks to first understand their current, very manual process backwards and forwards, inside and out. Learning the lingo, the rules, the exceptions to the rules and the exceptions to the exceptions. It wasn't until we fully understood the current process, that we could then build a new, better process in an fully automated way.
        </p><p className="mt-8 text-xl text-gray-500 leading-8">
        Defining the process required understanding touch points across a number of user types (Trip Leaders, Students, Parents, etc) and across time (before, during, and after a trip). What come through was a comprehensive mapping of the old process, the new process, and a roadmap forward.
        </p>
        <p className="mt-8 text-xl text-gray-500 leading-8">
        From that roadmap, we created and prioritized a backlog of work to be done. From there, we were ready to start development!
        </p>
        <p className="mt-8 text-xl text-gray-500 leading-8">
          As an internal team, we met briefly on a daily basis to discuss status. We met with the YouthWorks team on a weekly basis, to accomplish three things:
          <ul className="mt-8">
            <li><span class="font-bold">Review</span> work from the last week (sprint review)</li>
            <li><span class="font-bold">Analyze</span> how the work went and if there's any changes we need to make to process (sprint retrospective)</li>
            <li><span class="font-bold">Plan</span> the next week of work.</li>
          </ul>
        </p>
        <p className="mt-8 text-xl text-gray-500 leading-8">
        After a number of repetitions of the above, we had a finish product!
        </p>
    </div>
  },
  {
    'client': 'Vimbibe',
    'title': 'Enable wineries to connect with their customers over text.',
    'img_folder': process.env.PUBLIC_URL + '/projects/vb/',
    'challenge': <div><p className="mb-4">
      Highway 29 Creative works with wineries across the country to help them connect with their customers and sell more wine. They saw a need in the market for an app to allow those wineries to easily engage with their customers over a variety of new channels (starting with SMS).</p></div>,
    'solution': 'Highway 29 contracted Good Gravy to build Vimbibe, a solution that allows wineries to utilize the CRM they are already using to text with their customers.',
    'benefits': [
      {
        icon: ClockIcon,
        title: 'Saves Time',
        subtitle: 'Allows wineries to automate their messaging.'
      },
      {
        icon: ChatIcon,
        title: 'Better Service',
        subtitle: 'Adds touchpoints to the customer experience.'
      },
      {
        icon: RefreshIcon,
        title: 'All In One Marketing',
        subtitle: 'Vimbibe is deeply integrated into Commerce7, a leading wine CRM.'
      },
      {
        icon: DeviceMobileIcon,
        title: 'Engages Customers',
        subtitle: 'Research has shown that texted customers are highly engaged.'
      }
    ],
    'screens': [
      {
        image: 'inbox.png',
        title: 'Inbox',
        subtitle: 'Fully integrated inbox for customers to keep track of their conversations.'
      },
      {
        image: 'campaigns.png',
        title: 'Campaigns',
        subtitle: 'Create campaigns and send to one individual or a groups from the CRM.'
      },
      {
        image: 'automations.png',
        title: 'Automations',
        subtitle: 'Create triggers to automatically send messages.'
      },
      {
        image: 'referral.png',
        title: 'Referral Program',
        subtitle: 'Full referral program to incentivize customers to share the love.'
      },
      {
        image: 'wp-plugin.png',
        title: 'WordPress Plugin',
        subtitle: 'Add on WordPress plugin to allow customers to collect opt ins.'
      },
      {
        image: 'wp-plugin-2.png',
        title: 'WordPress Plugin',
        subtitle: 'SMS Opt in via the WordPress plugin'
      }
    ],
    techs : [
      {
        title: 'React',
        description: 'Front end of the application is built using React.'
      },
      {
        title: 'Laravel',
        description: 'Back end API is built using Laravel.'
      },
      {
        title: 'Twilio',
        description: 'Deep integration with Twilio for SMS sending, receiving, and number provision.'
      },
      {
        title: 'WordPress',
        description: 'Custom WordPress plugin to allow for customers to easily opt in.'
      },
      {
        title: 'Commerce7 CRM Integration',
        description: 'Web app is fully embedded into Commerce7 and fully integrated into their API.'
      },
      {
        title: 'Rebrandly Integration',
        description: 'Link shortening via an integration with the Rebrandly API'
      },
      {
        title: 'Stripe Integration',
        description: 'Payment handling via Stripe'
      }
    ],
    process: <div>
      <p className="mt-8 text-xl text-gray-500 leading-8">
        We worked with Simon from Highway 29 to develop a strong set of minimum features for the application. While keeping the long-term, multi-channel goals in mind (voice, video, etc), we focused on striking a balance between the best product we could put out without too much initial $ investment.
        </p>
        <p className="mt-8 text-xl text-gray-500 leading-8">
          We initially set out to focus on the customer texting experience, as this provided the most value. We developed this MVP, with a strong integration into an already existing CRM to tap into an existing market and technology set. 
        </p>
        <p className="mt-8 text-xl text-gray-500 leading-8">
          Since the initial launch, we've worked with Simon over the last year, to continue to develop other features. On a weekly basis, we meet, set priorities, and carry on with the most impactful features. Our work lately has focused on reducing friction for end users in opting in to texting, ultimately improving the experience of their current customers.
        </p>
    </div>
  }
]

  export default projects;