import './App.css';
import { SupportIcon } from '@heroicons/react/outline'
import { BadgeCheckIcon } from '@heroicons/react/outline'
import { CheckIcon } from '@heroicons/react/outline'
import projects from './data';
import {
  useParams,
  Link
} from "react-router-dom";

function Project() {
  const params = useParams();
  const project = projects[params.id-1];
  return (
    <div className="App">
      <section className="bg-white overflow-hidden">
      <div className="p-8">
      <Link className="block mt-2" to={"/"}>

                <img
                          className="h-16 w-auto"
                          src={process.env.PUBLIC_URL + '/gg.svg'}
                          alt="Workflow"
                        />
                        </Link>
        </div>
        <div className="relative pb-16 bg-white overflow-hidden">
      <div className="">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto lg:text-center mb-12">
          <h2 className="text-base text-gray-600 font-semibold tracking-wide uppercase">{project.client}</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {project.title}
          </p>
        </div>
        <div className="bg-gray-50 py-12">
          <img className="mx-auto w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:max-w-5xl" src={project.img_folder + 'hero.png'}/>
        </div>

        <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 text-left">
              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12">
                    <SupportIcon className="h-12 w-12 text-yellow-500" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Challenge</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {project.challenge}
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12">
                    <BadgeCheckIcon className="h-12 w-12 text-yellow-500" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Solution</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{project.solution}</dd>
              </div>
          </dl>
        </div>
      </div>
    </div>

           </div>

    </div>
    <div class="bg-gradient-to-r from-yellow-500 to-yellow-600 text-left">
        <div class="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
          <div class="grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
            {project.benefits.map((benefit)=>{
              return <div className="text-center">
                <div>
                  <benefit.icon className="h-16 w-16 text-white m-auto"/>
                </div>
                <div class="mt-6">
                  <h3 class="text-lg font-medium text-black">{benefit.title}</h3>
                  <p class="mt-2 text-base text-orange-200">
                    {benefit.subtitle}
                  </p>
                </div>
              </div>
            })}
            </div>
        </div>
      </div>
    


      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

    <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 xl:gap-x-8 bg-gray-50 py-12">
    {project.screens.map((screen)=>{
      return <li className="relative">
          <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-yellow-500 overflow-hidden">
            <img src={project.img_folder + screen.image} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
            <button type="button" className="absolute inset-0 focus:outline-none">
              <span className="sr-only">View details for </span>
            </button>
          </div>
          <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{screen.title}</p>
          <p className="block text-sm font-medium text-gray-500 pointer-events-none">{screen.subtitle}</p>
        </li>
      })}
    </ul>
    </div>
    </div>

    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto text-left">
          <h1>
            <span className="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              The Process
            </span>
          </h1>
          <div>
            {project.process}
          </div>
</div>
</div>
    </div>
      { /*
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

        <div className="relative">
          <img
            className="mx-auto h-8"
            src="https://tailwindui.com/img/logos/workcation-logo-yellow-600-mark-gray-800-and-yellow-600-text.svg"
            alt="Workcation"
          />
          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>
                &ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente
                alias molestiae. Numquam corrupti in laborum sed rerum et corporis.&rdquo;
              </p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-10 w-10 rounded-full"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">Judith Black</div>

                  <svg className="hidden md:block mx-1 h-5 w-5 text-yellow-600" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">CEO, Workcation</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
      */ }
    </section>
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">Technology</h2>
          <p className="mt-4 text-lg text-gray-500">
            This section is for the nerds.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {project.techs.map((tech) => (
            <div key={tech.title} className="relative">
              <dt>
                <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{tech.title}</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">{tech.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
    </div>
  );
}

export default Project;
