import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './Home';
import Project from './Project';

function App() {
  return (
    <Router>
      <Switch>
      <Route path={`/projects/:id`}>
          <Project />
        </Route>
        <Route path="/clicktocancel">
          <div class="p-5">
            <img src="/ctc.png"/>
          </div>
        </Route>
        <Route path="/sideways">
          <div class="h-screen w-screen flex justify-center bg-black text-white items-center text-6xl"><div class="transform rotate-90">Hello, will you be my friend?</div></div>
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
    /* Project project={projects[1]}/>
    */
  );
}

export default App;
